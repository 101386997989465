import React from 'react';
import LanguageProvider from 'LanguageProvider/index';
import translation from './translation';
import tw, { styled } from 'twin.macro';

import HeroBanner from './HeroBanner';
import CompanyVision from './CompanyVision';
import OurPromise from './OurPromise';
import OurReach from './OurReach';
import Leaders from './Leaders';
import Investors from './Investors';
import LayoutContainer from 'components/LayoutContainer';
import Certification from './Certification';
import DiverseTeam from './DiverseTeam';
import { PageSEOType } from 'components/types';
import StructuredDataScript from 'components/structuredData/StructuredDataScript';
import { useIntl } from 'react-intl';
import { get } from 'lodash';
import {
  COMPANY_NAME,
  IMG_LINK_LIST,
  StructuredDataTypes,
  STRUCTURED_DATA_CONTEXT,
} from 'components/constant';
import { AboutPageStructureDataType } from 'components/structuredData/types';
import { getFirstElementOfBreadcrumbList } from 'components/structuredData/structuredDataUtils';

interface Props {
  data: {
    allStrapiAboutUsPage: {
      nodes: PageSEOType[];
    };
  };
  pageUrl: string;
}

const PageWrapper = styled.div`
  ${tw`pt-[124px] lg:pt-[142px] bg-accent_grey_1`}
`;
const containerCss = 'px-[30px] lg:px-0';

const Index = ({ data, pageUrl }: Props) => {
  const { formatMessage } = useIntl();
  const metaTitle = get(data, 'allStrapiAboutUsPage.nodes[0].SEO.metaTitle');
  const metaDescription = get(
    data,
    'allStrapiAboutUsPage.nodes[0].SEO.metaDescription',
  );

  const structuredData: AboutPageStructureDataType = {
    '@context': STRUCTURED_DATA_CONTEXT,
    '@type': StructuredDataTypes.ABOUT_PAGE,
    name: metaTitle,
    description: metaDescription,
    url: pageUrl,
    author: {
      '@type': StructuredDataTypes.ORGANIZATION,
      name: COMPANY_NAME,
      logo: IMG_LINK_LIST.logo,
    },
    mainEntityOfPage: {
      '@type': StructuredDataTypes.WEB_PAGE,
      '@id': `${pageUrl}#webpage`,
    },
    breadcrumb: {
      '@type': StructuredDataTypes.BREADCRUMB_LIST,
      '@id': `${pageUrl}#breadcrumb`,
      itemListElement: [
        getFirstElementOfBreadcrumbList(formatMessage),
        {
          '@type': StructuredDataTypes.LIST_ITEM,
          position: 2,
          name: formatMessage({ id: 'structuredData.about' }),
        },
      ],
    },
  };
  return (
    <LanguageProvider messages={translation}>
      <StructuredDataScript data={structuredData} />
      <PageWrapper>
        <LayoutContainer className={containerCss}>
          <HeroBanner />
          <CompanyVision />
        </LayoutContainer>
        <div className="bg-white">
          <LayoutContainer className={containerCss}>
            <OurPromise />
          </LayoutContainer>
        </div>
        <div className="bg-accent_blue_2">
          <LayoutContainer className={containerCss}>
            <OurReach />
          </LayoutContainer>
        </div>
        <div className="bg-white">
          <LayoutContainer className={containerCss}>
            <Leaders />
          </LayoutContainer>
        </div>
        <div className="bg-accent_grey_1">
          <LayoutContainer className={containerCss}>
            <Investors />
          </LayoutContainer>
        </div>
        <div className="bg-accent_blue_2">
          <LayoutContainer className={containerCss}>
            <Certification />
          </LayoutContainer>
        </div>
        <LayoutContainer className={containerCss}>
          <DiverseTeam />
        </LayoutContainer>
      </PageWrapper>
    </LanguageProvider>
  );
};
export default Index;
