import * as React from 'react';
import translation from 'intl';
import LanguageProvider from 'LanguageProvider';
import { graphql } from 'gatsby';
import About from 'components/pages/about';
import NewLayout from 'components/NewLayout';
import SEOGraphQL from 'components/SEOGraphQL';
import { PageSEOType } from 'components/types';
import { PREFIX_CANONICAL_URL } from 'components/constant';

interface AboutPageProps {
  data: {
    allStrapiAboutUsPage: {
      nodes: PageSEOType[];
    };
  };
}

const pageUrl = `${PREFIX_CANONICAL_URL}/about-us`;

const AboutPage = ({ data }: AboutPageProps) => {
  return (
    <LanguageProvider messages={translation}>
      <NewLayout headerStyle="light">
        <About data={data} pageUrl={pageUrl} />
      </NewLayout>
    </LanguageProvider>
  );
};
export default AboutPage;

export const Head = ({ data }: AboutPageProps) => {
  return (
    <SEOGraphQL
      data={data.allStrapiAboutUsPage.nodes[0]}
      canonicalURL={pageUrl}
    />
  );
};

export const query = graphql`
  query {
    allStrapiAboutUsPage {
      nodes {
        seo {
          preventIndexing
        }
        SEO {
          metaDescription
          metaTitle
          metaSocial {
            title
            description
            image {
              data {
                attributes {
                  url
                }
              }
            }
          }
        }
      }
    }
  }
`;
