import React from 'react';
import tw, { styled } from 'twin.macro';
import { graphql, useStaticQuery } from 'gatsby';
import { PromiseCardType } from './types';

interface Props {}

const OurPromiseContainer = styled.div`
  ${tw`pt-[60px] pb-[40px] lg:py-[120px]`}
  h2 {
    ${tw`text-[24px] !font-[500] leading-[150%] lg:text-[36px] lg:leading-[54px] mb-2 text-base_text text-center`}
  }
`;

const ContentGrid = tw.div`
  grid lg:grid-cols-2 gap-6 text-left
`;

const ContentItem = tw.div`
  grid lg:grid-cols-3 bg-[white] shadow-[0px_1px_5px_rgba(41,45,55,0.15)]
`;
const ImageWrapper = tw.div`
h-[235px] lg:h-[full] w-full
`;
const ContentText = tw.div`
  lg:col-span-2 flex flex-col justify-center px-[24px] pt-[16px] pb-[21px] lg:py-[24px]
`;
const Description = tw.div`
  whitespace-pre-line text-[14px] leading-[21px] text-base_text lg:text-[18px] lg:leading-[27px] text-center mb-[21px] lg:mb-[48px]
`;
const CardTitle = tw.p`
  text-[20px] leading-[30px] font-[500] text-hubble_blue lg:text-[24px] lg:leading-[115%] mb-2
`;
const CardDescription = tw.div`
  text-[14px] lg:text-[16px] leading-[150%] text-base_text
`;

const OurPromise = ({}: Props) => {
  const data = useStaticQuery(graphql`
    query {
      allStrapiAboutUsPage {
        nodes {
          CoreValuesHeader
          CoreValuesDescription
          CoreValueItem {
            SubHeader
            Description
            id
            image {
              url
            }
          }
        }
      }
    }
  `);
  const { CoreValuesHeader, CoreValuesDescription, CoreValueItem } =
    data.allStrapiAboutUsPage.nodes[0];
  return (
    <OurPromiseContainer>
      <h2>{CoreValuesHeader}</h2>
      <Description>{CoreValuesDescription}</Description>
      <ContentGrid>
        {CoreValueItem.map(
          ({
            id,
            SubHeader: title,
            Description: description,
            image: { url: imgSrc },
          }: PromiseCardType) => (
            <ContentItem key={id}>
              <ImageWrapper
                data-testid="our-promise-card-img"
                style={{
                  background: `url(${imgSrc}) no-repeat center center /cover`,
                }}
              />
              <ContentText>
                <CardTitle>{title}</CardTitle>
                <CardDescription>{description}</CardDescription>
              </ContentText>
            </ContentItem>
          ),
        )}
      </ContentGrid>
    </OurPromiseContainer>
  );
};
export default OurPromise;
