import React from 'react';
import tw, { styled } from 'twin.macro';
import { graphql, useStaticQuery } from 'gatsby';

const LeadersContainer = styled.div`
  ${tw`text-center py-[40px] lg:py-[120px] text-[16px] lg:text-[18px] `}
  h2 {
    ${tw`text-[24px] !font-[500] leading-[36px] text-base_text mb-2 lg:text-[36px] lg:leading-[54px] `}
  }
`;

const LeaderPhoto = styled.div`
  ${tw`h-[217px] lg:h-[280px] bg-accent_grey_1 mb-2 lg:mb-6 relative`}
`;

const LeaderName = tw.div`
  text-[20px] lg:text-[24px] leading-[30px] lg:leading-[115%] text-base_text lg:font-[500] px-2 lg:mb-2
`;

const LeaderPosition = tw.div`
  lg:whitespace-pre-line text-[14px] leading-[150%] text-base_text lg:text-[20px] lg:leading-[28px] px-2 lg:pr-6
`;

interface Props {}
const Leaders = ({}: Props) => {
  const data = useStaticQuery(graphql`
    query {
      allStrapiAboutUsPage {
        nodes {
          LeadersHeader
          LeadersSubHeader
          Leader {
            id
            Name
            JobTitle
            Image {
              url
            }
          }
        }
      }
    }
  `);
  const { LeadersHeader, LeadersSubHeader, Leader } =
    data.allStrapiAboutUsPage.nodes[0];
  return (
    <LeadersContainer>
      <h2>{LeadersHeader}</h2>
      <div className="text-[16px] lg:text-[18px] leading-[24px] lg:leading-[27px] text-base_text mb-[36px] lg:mb-[48px]">
        {LeadersSubHeader}
      </div>
      <div className="grid md:grid-cols-3 gap-6 text-left lg:px-[140px]">
        {Leader.map(
          ({
            id,
            JobTitle: title,
            Name: name,
            Image: { url: imgSrc },
          }: Record<string, any>) => (
            <div key={id}>
              <LeaderPhoto>
                <img
                  src={imgSrc}
                  alt="leader img"
                  className="lg:w-full object-contain absolute bottom-0 max-h-full left-[50%] translate-x-[-50%]"
                />
              </LeaderPhoto>
              <LeaderName>{name}</LeaderName>
              <LeaderPosition>{title}</LeaderPosition>
            </div>
          ),
        )}
      </div>
    </LeadersContainer>
  );
};
export default Leaders;
