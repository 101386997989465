import React from 'react';
import tw, { styled } from 'twin.macro';
import Link from 'components/Link';
import { graphql, useStaticQuery } from 'gatsby';

interface Props {}
interface ButtonProps {
  label: string;
  to: string;
}

const InvestorsContainer = styled.div`
  ${tw`md:text-center py-[40px] md:py-[120px]`}

  h2 {
    ${tw`text-[24px] font-[500] leading-[36px] text-base_text md:text-[36px] md:leading-[150%] mb-0 md:mb-2`}
  }
`;

const MobileSubHeading = tw.div`
  block md:hidden text-[16px] leading-[28px] mb-2
`;
const Description = tw.div`
  whitespace-pre-line text-[14px] leading-[145%] md:text-[18px] leading-[150%] text-base_text mb-[34px] md:mb-[30px] md:px-[180px]
`;
const LogoImg = tw.img`
  max-h-[27px] md:max-h-[45px] self-center w-full object-contain
`;
const LogoWrapper = tw.div`
  grid grid-cols-3 md:grid-cols-6 gap-4 md:gap-6 md:px-[40px]
`;

const Button = ({ label, to }: ButtonProps) => {
  return (
    <Link
      to={to}
      className="text-[16px] leading-[150%] font-[500] text-hubble_blue border border-hubble_blue bg-white px-3 py-2 hover:bg-hubble_blue hover:text-white inline-block"
    >
      {label}
    </Link>
  );
};

const Investors = ({}: Props) => {
  const data = useStaticQuery(graphql`
    query {
      allStrapiAboutUsPage {
        nodes {
          BoardInvestorsHeader
          BoardInvestorsDescription
          BoardInvestorsButtonLink
          BoardInvestorsButtonLabel
          InvestorsLogo {
            url
          }
        }
      }
    }
  `);
  const {
    BoardInvestorsHeader,
    BoardInvestorsDescription,
    InvestorsLogo,
    BoardInvestorsButtonLink,
    BoardInvestorsButtonLabel,
  } = data.allStrapiAboutUsPage.nodes[0];
  return (
    <InvestorsContainer>
      <h2>{BoardInvestorsHeader}</h2>
      <MobileSubHeading>{BoardInvestorsHeader}</MobileSubHeading>

      <div className="block md:hidden mb-6">
        <Button
          label={BoardInvestorsButtonLabel}
          to={BoardInvestorsButtonLink}
        />
      </div>

      <Description>{BoardInvestorsDescription}</Description>

      <div className="hidden md:block mb-[32px]">
        <Button
          label={BoardInvestorsButtonLabel}
          to={BoardInvestorsButtonLink}
        />
      </div>

      <LogoWrapper>
        {InvestorsLogo.map(({ url: logo }: { url: string }, index: number) => (
          <LogoImg key={index} src={logo} alt="invertors logo" />
        ))}
      </LogoWrapper>
    </InvestorsContainer>
  );
};
export default Investors;
