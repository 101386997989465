import Link from 'components/Link';
import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import tw from 'twin.macro';
import AboutCarousel from './components/AboutCarousel';

interface DiverseTeamProps {}
interface CountryTagProps {
  name: string;
  slug: string;
}

const StyledTag = tw(Link)`
text-[12px] lg:text-[14px] py-2 px-4 text-[#C16A1B] bg-[#FCF9EC]
`;
const ComponentWrapper = tw.div`
pb-10 lg:py-[80px] lg:grid lg:grid-cols-2 gap-[60px]
`;
const ContentWrapper = tw.div`
py-[40px] text-base_text text-center lg:text-left
`;
const SectionHeader = tw.p`
text-[24px] lg:text-[36px] font-[500] leading-[36px] lg:leading-[54px] mb-2
`;
const SectionDescription = tw.p`
text-[14px] leading-[21px] lg:text-[18px] leading-[27px] mb-4
`;
const CountryTagsWrapper = tw.div`
flex gap-2 flex-wrap items-center justify-center lg:justify-start mb-6
`;
const StyledLink = tw(Link)`
text-hubble_blue border border-hubble_blue text-[14px] lg:text-[16px] font-[500] hover:text-hubble_blue hover:bg-accent_grey_2 py-2 px-3
`;
const Image = tw.img`
rounded-[10px] h-[234px] md:w-[206px] md:h-[206px] lg:w-[306px] lg:h-[306px] object-cover
`;

const CountryTag = ({ name, slug }: CountryTagProps) => {
  return (
    <StyledTag to={`/careers-at-hubble?location=${slug}`}>{name}</StyledTag>
  );
};

const DiverseTeam = (props: DiverseTeamProps) => {
  const data = useStaticQuery(graphql`
    query {
      allStrapiAboutUsPage {
        nodes {
          DiverseTeamHeader
          DiverseTeamDescription
          DiverseTeamButtonLink
          DiverseTeamButtonLabel
          DiverseTeamImages {
            url
            id
          }
          job_locations {
            name
            slug
            id
          }
        }
      }
    }
  `);
  const {
    DiverseTeamHeader,
    DiverseTeamDescription,
    DiverseTeamButtonLink,
    DiverseTeamButtonLabel,
    DiverseTeamImages,
    job_locations: jobLocation,
  } = data.allStrapiAboutUsPage.nodes[0];
  return (
    <ComponentWrapper>
      <ContentWrapper>
        <SectionHeader>{DiverseTeamHeader}</SectionHeader>
        <SectionDescription>{DiverseTeamDescription}</SectionDescription>
        <CountryTagsWrapper>
          {jobLocation.map(({ id, name, slug }: Record<string, any>) => (
            <CountryTag key={id} name={name} slug={slug} />
          ))}
        </CountryTagsWrapper>
        <StyledLink to={DiverseTeamButtonLink}>
          {DiverseTeamButtonLabel}
        </StyledLink>
      </ContentWrapper>

      <AboutCarousel>
        {DiverseTeamImages.map(({ url, id }: { url: string; id: string }) => (
          <div className="px-2 " key={id}>
            <Image src={url} alt="team" />
          </div>
        ))}
      </AboutCarousel>
    </ComponentWrapper>
  );
};

export default DiverseTeam;
