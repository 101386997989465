import React from 'react';
import tw, { styled } from 'twin.macro';
import { graphql, useStaticQuery } from 'gatsby';

const OurReachContainer = styled.div`
  ${tw`lg:text-center py-[40px] lg:py-[60px]`}
  * {
    ${tw`text-white`}
  }
`;
const Heading = tw.p`
  text-[24px] lg:text-[36px] leading-[150%] text-white font-[500] mb-2 text-center
`;
const SubHeading = tw.p`
  text-[14px] lg:text-[18px] leading-[150%] mb-6 lg:mb-[48px] text-center px-[20px]
`;
const StatsWrapper = tw.div`
  grid grid-cols-2 lg:grid-cols-5 gap-[18px] my-[24px] text-center
`;
const Statistics = tw.p`
  !text-accent_grey_1 text-[20px] lg:text-[36px] font-[500] leading-[30px] lg:leading-[54px] mb-0
`;
const Title = tw.div`
  text-[14px] lg:text-[20px] leading-[150%]
`;

interface Props {}
const OurReach = ({}: Props) => {
  const data = useStaticQuery(graphql`
    query {
      allStrapiAboutUsPage {
        nodes {
          CompanyRecordedHeader
          CompanyRecordedDescription
          StatsAndUnits {
            header
            description
            id
          }
        }
      }
    }
  `);
  const { CompanyRecordedHeader, CompanyRecordedDescription, StatsAndUnits } =
    data.allStrapiAboutUsPage.nodes[0];
  return (
    <OurReachContainer>
      <Heading>{CompanyRecordedHeader}</Heading>
      <SubHeading>{CompanyRecordedDescription}</SubHeading>
      <StatsWrapper>
        {StatsAndUnits.map(
          (
            { id, description: title, header: number }: Record<string, any>,
            index: number,
          ) => (
            <div
              key={id}
              className={
                index === 4 ? 'col-span-full text-center lg:col-auto' : ''
              }
            >
              <Statistics>{number}</Statistics>
              <Title>{title}</Title>
            </div>
          ),
        )}
      </StatsWrapper>
    </OurReachContainer>
  );
};
export default OurReach;
