import React from 'react';
import tw, { styled } from 'twin.macro';
import { graphql, useStaticQuery } from 'gatsby';

const HeadText1 = tw.p`
  text-[36px] lg:text-[48px] leading-[43px] lg:leading-[61px] font-[600] lg:!font-[500] mb-3 lg:mb-6
`;
const HeadText2 = tw.p`
  text-[20px] lg:text-[48px] leading-[30px] lg:leading-[61px] !font-[400] lg:!font-[500] mb-[37px] lg:mb-10
`;

const HeroText = styled.div`
  ${tw`mb-[24px] `}
  * {
    ${tw`text-hubble_blue`}
  }
`;

const PreTitle = tw.div`
  text-[14px] lg:text-[18px] lg:font-[500] leading-[21px] lg:leading-[150%] mb-2
`;

const HeroPhotosWrapper = styled.div`
  ${tw`grid grid-flow-row-dense lg:grid-cols-11 grid-cols-2 gap-4 `}
  div {
    background-color: #ccc;
    ${tw`rounded`}
  }
`;
const ImageBase = tw.div`
  bg-no-repeat bg-center bg-cover
`;

const ImageLarge = styled(ImageBase)`
  ${tw`lg:col-span-4 row-span-2 h-[365px]`}
  &.full {
    ${tw`lg:col-span-4 col-span-2`}
  }
`;
const ImageSmall = tw(ImageBase)`
  lg:col-span-3 h-[175]
`;

interface Props {}
const HeroBanner = ({}: Props) => {
  const data = useStaticQuery(graphql`
    query {
      allStrapiAboutUsPage {
        nodes {
          PageHeader
          PageSubHeader
          HeroImages {
            url
          }
          PageDescription
        }
      }
    }
  `);
  const { PageHeader, PageSubHeader, HeroImages, PageDescription } =
    data.allStrapiAboutUsPage.nodes[0];

  return (
    <>
      <HeroText>
        <PreTitle>{PageSubHeader}</PreTitle>
        <HeadText1>{PageHeader}</HeadText1>
        <HeadText2>{PageDescription}</HeadText2>
      </HeroText>

      <HeroPhotosWrapper>
        <ImageLarge
          style={{ backgroundImage: `url(${HeroImages[0].url})` }}
        ></ImageLarge>
        <ImageSmall
          style={{ backgroundImage: `url(${HeroImages[1].url})` }}
        ></ImageSmall>
        <ImageLarge
          className="full"
          style={{ backgroundImage: `url(${HeroImages[3].url})` }}
        ></ImageLarge>
        <ImageSmall
          style={{ background: `url(${HeroImages[2].url}) center top /cover` }}
        ></ImageSmall>
      </HeroPhotosWrapper>
    </>
  );
};
export default HeroBanner;
