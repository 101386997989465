import React from 'react';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

interface AboutCarouselProps {
  children: React.ReactNode;
}

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 2,
    partialVisibilityGutter: 20,
  },
  tablet: {
    breakpoint: { max: 1024, min: 620 },
    items: 3,
    partialVisibilityGutter: 30,
  },
  mobile: {
    breakpoint: { max: 620, min: 0 },
    items: 2,
    partialVisibilityGutter: 20,
  },
};

const AboutCarousel = ({ children }: AboutCarouselProps) => {
  return (
    <Carousel
      ssr
      partialVisible
      responsive={responsive}
      infinite={true}
      removeArrowOnDeviceType={['tablet', 'mobile', 'desktop']}
      autoPlaySpeed={3000}
      swipeable
      draggable
      autoPlay={true}
    >
      {children}
    </Carousel>
  );
};

export default AboutCarousel;
