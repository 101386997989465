import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import tw, { styled } from 'twin.macro';
import { CompanyVisionCardType } from './types';

interface Props {}

const CompanyVisionContainer = styled.div`
  ${tw`lg:text-center py-[40px] lg:py-[120px]`}
  h1 {
    ${tw`text-[20px] !font-normal leading-[30px] lg:text-[36px] lg:!font-medium lg:leading-[54px] text-base_text mb-0`}
  }
`;
const VisionContent = styled.div`
  ${tw`grid grid-cols-1 lg:w-[800px] gap-4 lg:gap-[33px] mt-[24px] lg:mt-[32px] mx-auto`}
  h3 {
    ${tw`text-[24px] lg:text-[20px] leading-[150%] text-accent_blue_1 lg:text-hubble_blue mb-2`}
  }
  div {
    ${tw`text-[14px] lg:text-[16px] leading-[21px] text-hubble_blue lg:text-base_text leading-[21px] lg:leading-[24px]`}
  }
`;

interface Props {}
const CompanyVision = ({}: Props) => {
  const data = useStaticQuery(graphql`
    query {
      allStrapiAboutUsPage {
        nodes {
          CompanyVisionHeader
          CompanyVisionItem {
            header
            description
            id
          }
        }
      }
    }
  `);
  const { CompanyVisionHeader, CompanyVisionItem } =
    data.allStrapiAboutUsPage.nodes[0];
  return (
    <CompanyVisionContainer>
      <h1>{CompanyVisionHeader}</h1>
      <VisionContent>
        {CompanyVisionItem.map(
          ({ id, header, description }: CompanyVisionCardType) => (
            <div key={id}>
              <h3>{header}</h3>
              <div>{description}</div>
            </div>
          ),
        )}
      </VisionContent>
    </CompanyVisionContainer>
  );
};
export default CompanyVision;
